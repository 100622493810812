<template>
  <Layout :seccion="true">
    <div class="row votosPorCandidaturaMovil" v-if="cargaTerminada">
      <div class="col-12 col-md-10 mx-auto">
        <div class="grilla-selects">
          <div class="secciones">
            <select v-model="seccion" @change="cambiarSeccion">
              <option
                v-for="(seccion, index) in listaSecciones"
                :key="index"
                :value="seccion.seccion_id"
              >
                Sección:
                 {{ seccion.seccion_id }}
              </option>
            </select>
          </div>
          <div class="casillas">
            <select v-model="casilla" @change="irADetalleDeCasilla">
              <option :value="null">Todas</option>
              <option
                v-for="(casilla, index) in listaCasillas.datos_casillas"
                :key="index"
                :value="casilla.tipo_casilla"
              >
                {{ casilla.tipo_casilla }}
              </option>
            </select>
          </div>
        </div>

        <h4>
          {{ titulo }} - <b class="primario">Sección - Casillas</b>
        </h4>

        <p>
          <router-link :to="`/${eleccion}/ENT/VPC`" class="estado-volver">
            Baja California Sur
          </router-link>
          /
          <router-link :to="`/${eleccion}/DET/VPC/${distrito}`" class="estado-volver">
            <span v-if="eleccion != 'A'">
              Distrito {{distrito}}. {{distrito_nombre}}
            </span>
            <span v-else>
              Municipio {{ayuntamiento.municipio_id}}. {{ayuntamiento.municipio_descripcion}}
            </span>
          </router-link>
          /
          <b>Sección {{ seccion }}</b>
        </p>

        <p>
          El total de votos calculando y porcentaje que se muestran, se refieren a los votos
          asentados en las Actas PREP hasta el momento.
        </p>
        <p>
          Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos.
          No obstante, al considerar todos los decimales, suman 100%.
        </p>


        <div class="votosEnActasContabilizadas mt-5">
          <p class="titulo">Votos en Actas Contabilizadas</p>
        </div>

        <div class="row">
          <div
            class="col-12 col-md-6 mb-4"
            v-for="(
              candidato, index
            ) in votosXPartidoPoliticoNivelSeccion.datos_candidaturas"
            :key="index"
          >
            <!--<div class="resumen-voto-candidatura" :class="{ 'ganador': candidato.ganador }">-->
            <div class="resumen-voto-candidatura">
              <div class="grilla-datos-candidatura">
                <div class="foto">
                  <!-- <img src="@/assets/avatar-persona.svg" :alt="candidato.nombre"> -->
                  <!-- <Avatar :colores="colores" /> -->
                  <img
                    :src="candidato.candidatura_logo"
                    :alt="candidato.candidatura_nombre"
                  />
                </div>
                <div class="nombre">
                  <!-- <b>{{ candidato.candidatura_nombre }} </b> -->
                </div>
              </div>
              <div class="grilla-votos mb-2">
                <div class="total text-left">
                  <p class="mb-0 font-weight-bold">
                    Total de votos <br />
                    <span>
                      {{ candidato.candidatura_total_votos_numero }}
                    </span>
                  </p>
                </div>
                <div class="porcentaje text-right">
                  <p class="mb-0 font-weight-bold">
                    <span>
                      {{ candidato.candidatura_total_votos_porcentaje }}%
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p>
            Por representación, los decimales de los porcentajes muestran sólo cuatro dígitos.
            No obstante, al considerar todos los decimales, suman 100%.
          </p>
        </div>

        <div class="resumenDeLaVotacion mb-4">
          <p class="titulo">Resumen de la votación</p>
          <ResumenVotacion
            texto="Votos Acumulados"
            :numero="resumen.total_votos_acumulados_numero"
            :porcentaje="
              resumen.total_votos_acumulados_porcentaje"
          />
          <ResumenVotacion
            texto="Candidaturas no registradas"
            :numero="resumen.total_votos_candidatura_noregistrada_numero"
            :porcentaje="
              resumen.total_votos_candidatura_noregistrada_porcentaje"
          />
          <ResumenVotacion
            texto="Nulos"
            :numero="resumen.total_votos_nulos_numero"
            :porcentaje="resumen.total_votos_nulos_porcentaje"
          />
        </div>

        <div class="totalVotos mb-4">
          <h5 class="text-center"><b>Total de votos</b></h5>

          <h1 class="total mb-0">
            {{ resumen.total_votos_general_numero }}
          </h1>
          <p class="porcentaje mb-3">
            {{ resumen.total_votos_general_porcentaje }}%
          </p>
        </div>


        <div class="votosEnActasContabilizadas">
          <p class="titulo">Estadística de Casillas</p>

          <h5 class="font-weight-bold mb-3">
            Participación ciudadana con base en la Lista Nominal de las Actas
            PREP Contabilizadas
          </h5>
        </div>

        <div
          v-for="(casilla, index) in estadisticas.datos_casillas"
          class="votosTotalesAvance mb-4"
          :key="index"
        >
          <div class="grilla mb-2">
            <div class="leyenda">
              <p class="font-weight-bold mb-0">
                {{ casilla.casilla_nombre }}
              </p>
            </div>
            <div class="total">
              <h4 class="mb-0 text-right">{{ casilla.participacion_ciudadana_porcentaje }}%</h4>
            </div>
          </div>
          <b-progress max="100" :value="casilla.participacion_ciudadana_porcentaje" />
        </div>

       

        <ObservacionesActas />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Movil/layouts/Layout';
import ResumenVotacion from '@/components/Movil/componentes/ResumenVotacion';
import ObservacionesActas from '@/components/Movil/componentes/ObservacionesActas';
import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar';

export default {
  name: 'VpcDetalleDelDistritoYSeccionMovil',
  components: {
    Layout,
    ResumenVotacion,
    ObservacionesActas,
  },
  data() {
    return {
      seccion: this.$route.params.seccion,
      casilla: null,
    };
  },
  methods: {
    cambiarSeccion() {
      this.$router.push(
        `/${this.eleccion}/Secciones/${this.distrito}/${this.seccion}`,
      );
    },
    irADetalleDeCasilla() {
      // console.log(this.casilla)
      this.$router.push(
        `/${this.eleccion}/Secciones/${this.distrito}/${this.seccion}/${this.casilla}`,
      );
    },
  },
  computed: {
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(
        (d) => d.id == this.distrito,
      ).descripcion;
    },
    titulo() {
      if (this.$route.params.eleccion === 'G') {
        return 'Gubernatura';
      } if (this.$route.params.eleccion === 'A') {
        return 'Ayuntamiento';
      }
      return 'Diputaciones';
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
    distrito() {
      return this.$route.params.distrito;
    },
    ayuntamiento() {
      return this.$store.state.elementosGenerales.datos_municipios[this.distrito - 1];
    },
    detalle() {
      return this.$store.state.secciones.uno;
    },
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
    colores() {
      return obtenerColoresDeAvatar(this);
    },
    colorEsperadas() {
      if (this.$store.state.esOscuro) {
        return 'fd2a71';
      }
      return 'd2127e';
    },
    colorCapturadas() {
      if (this.$store.state.esOscuro) {
        return 'fe9db0';
      }
      return '79144c';
    },
    colorContabilizadas() {
      if (this.$store.state.esOscuro) {
        return 'fec5cd';
      }
      return 'efb5d3';
    },
    listaSecciones() {
      if (this.eleccion != 'A') {
        return this.$store.state.elementosGenerales.datos_secciones
          .distritos[this.distrito - 1]
          .datos_secciones;
      }
      return this.$store.state.elementosGenerales
        .datos_secciones_por_municipio[this.distrito - 1]
        .datos_secciones;
    },
    listaCasillas() {
      return this.$store.state.elementosGenerales.datos_casillas.filter(
        (seccion) => parseInt(seccion.seccion_id) == parseInt(this.seccion),
      )[0];
    },
    votosXPartidoPoliticoNivelSeccion() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gVotosXPartidoPoliticoNivelSeccion.datos_distritos[
          this.$route.params.distrito - 1
        ].datos_secciones.filter(
          (seccion) => parseInt(seccion.seccion_id)
            === parseInt(this.$route.params.seccion),
        )[0];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosXPartidoPoliticoNivelSeccion.datos_municipios[
          this.$route.params.distrito - 1
        ].datos_secciones.filter(
          (seccion) => parseInt(seccion.seccion_id)
            === parseInt(this.$route.params.seccion),
        )[0];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosXPartidoPoliticoNivelSeccion.datos_distritos[
          this.$route.params.distrito - 1
        ].datos_secciones.filter(
          (seccion) => parseInt(seccion.seccion_id)
            === parseInt(this.$route.params.seccion),
        )[0];
      }
    },
    resumen() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gdResumenNivelSeccion.distritos[
          this.$route.params.distrito - 1
        ].datos_secciones.filter(
          (seccion) => parseInt(seccion.seccion_id)
            === parseInt(this.$route.params.seccion),
        )[0];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenNivelSeccion.distritos[
          this.$route.params.distrito - 1
        ].datos_secciones.filter(
          (seccion) => parseInt(seccion.seccion_id)
            === parseInt(this.$route.params.seccion),
        )[0];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aResumenNivelSeccion.municipios[
          this.$route.params.distrito - 1
        ].datos_secciones.filter(
          (seccion) => parseInt(seccion.seccion_id)
            === parseInt(this.$route.params.seccion),
        )[0];
      }
    },
    estadisticas() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gEstadisticasNivelCasilla.datos_distritos[
          this.$route.params.distrito - 1
        ].datos_secciones.filter(
          (seccion) => parseInt(seccion.seccion_id)
            === parseInt(this.$route.params.seccion),
        )[0];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dEstadisticasNivelCasilla.datos_distritos[
          this.$route.params.distrito - 1
        ].datos_secciones.filter(
          (seccion) => parseInt(seccion.seccion_id)
            === parseInt(this.$route.params.seccion),
        )[0];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aEstadisticasNivelCasilla.datos_municipios[
          this.$route.params.distrito - 1
        ].datos_secciones.filter(
          (seccion) => parseInt(seccion.seccion_id)
            === parseInt(this.$route.params.seccion),
        )[0];
      }
    },
    anchoPantalla() {
      return this.$store.state.anchoPantalla;
    },
  },
  watch: {
    anchoPantalla(val) {
      if (val >= 992) {
        const { eleccion, distrito, seccion } = this.$route.params;
        this.$router.push(
          `/${eleccion}/Secciones/${distrito}/${seccion}`,
        );
      }
    },
    $route(val) {
      if (val.params.seccion !== this.seccion) {
        this.seccion = val.params.seccion;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ganador {
  border: 3px solid #d2127e;
  .grilla-votos {
    p > span {
      color: #d2127e;
    }
  }
}

.resumen-voto-candidatura {
  background-color: #f5f5f5;
  padding: 10px;

  .grilla-datos-candidatura {
    display: grid;
    grid-template-columns: 75px 1fr 30px;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;

    .foto {
      img {
        max-height: 50px;
        padding: 7px;
        display: block;
        margin: 0 auto;
      }
    }

    .nombre {
      font-size: 16px;
    }
  }

  .grilla-votos,
  .grilla-distribucion-votos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 30px;
  }

  .grilla-distribucion-votos {
    background-color: #d3d3ec;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
    padding: 10px;
  }
}

.btn-link {
  border-radius: 15px;
  background-color: #582D73;
  border-color: transparent;
}

.grilla-selects {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 20px;

  select {
    border: 1px solid #fff;
    border-radius: 15px;
    width: 100%;
    padding: 15px;
    appearance: none;
    color: #fff;

    &:hover {
      cursor: pointer;
    }
  }

  .secciones > select {
    background-color: #C791A8;
    color: #000;
    font-weight: bold;
  }
  .casillas > select {
    background-color: #C791A8;
    color: #000;
    font-weight: bold;
  }
}
</style>
