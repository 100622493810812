<template>
  <div>
    <VpcDetalleDelDistritoYSeccionMovil />
  </div>
</template>

<script>
import VpcDetalleDelDistritoYSeccionMovil from '@/components/Movil/vistas/VotosPorCandidaturas/DetalleDelDistritoYSeccion';

export default {
  name: 'DetalleDelDistrito',
  components: {
    VpcDetalleDelDistritoYSeccionMovil,
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2024 - Entidad - Votos Por Candidaturas - Detalles del Distrito y de la Sección',
  },
  computed: {
    anchoPantalla() {
      return this.$store.state.anchoPantalla;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
